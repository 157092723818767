.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.box{
  display: flex;
}
.fondo-blanco {
  /*  background-color: #fff !important;*/
    border-radius: 0.125rem !important;
    
}
.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(68,89,150,1) 0%, rgba(13,89,193,1) 81%) !important;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}


.content-table th{
  padding: 12px 15px;
}
.content-table td {
  padding: 5px 15px;
}
.content-table td {
  font-size: 15px;
}
.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:hover td {
  background: #e0e0e0;
  cursor: pointer;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid rgba(13,89,193,1);
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
.mag_input_nota{
  margin-top: 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fontRoboto{
  color: #3C4858 !important;
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 300 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
}

.fontTabla{
  /*color: #000 !important;*/
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
  font-size: 0.95rem;
}
.lista_materias{
   margin-bottom: 10px;
   font-weight: bold !important;
}

.materias{
   /*text-decoration: underline !important ; */
   margin-right: 10px !important;
}

.App-link {
  color: #61dafb;
}
.input-maximo{
    width: 100% !important;
}
.iconoColorRosa{
  color: #d81b60 !important;
}
.dialog_indicador{
  width: 400px !important;
}
.LeftMargin{
    margin-left: 5px !important;
}
.backdrop_fondo {
  z-index: 1201 !important;
  color: '#fff' !important
}

.flex-container {
  display: flex;  
}

.flex-container > div {
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
