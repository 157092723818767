.imgloading{
    position: absolute;
    left: 50%;
    top: 50%;      
    width: 64px;
    height: 64px;
    margin-left: -32px; /*half width*/
    margin-top: -32px; /*half height*/      
    background-image: url('/images/loading.gif')
}
.PaddingPaper {
    padding: .75rem 1.25rem !important;
}
.MargenBoton {
    margin-right: .25rem !important;
}
.MargenButton{
    margin-bottom: .45rem !important;
}
.AltoFila{
    height: 40px !important;
}
.ColorAzul{
    color: #d81b60 !important;
}
.FilaEncabezadoTabla{
   /* background: rgba(95,117,230,1);
    background: -moz-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(95,117,230,1)), color-stop(50%, rgba(63,81,181,1)), color-stop(100%, rgba(95,117,230,1)));
    background: -webkit-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -o-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -ms-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: linear-gradient(to bottom, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f75e6', endColorstr='#5f75e6', GradientType=0 );*/
    font-weight:bold !important;
    font-size: 0.95em !important;
    line-height: 1.42857143 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    
}
.Titulo{
    font-size: 1.3em !important;
    color: #3C4858 !important;
    margin-top: 0 !important;
    min-height: auto !important;
    font-weight: 300 !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    margin-bottom: 0px;
    text-decoration: none !important;
    line-height: 1.4em !important;

}
.msginfo{
    font-size: 1.0em !important;
    color: #3C4858 !important;
    margin-top: 0 !important;
    min-height: auto !important;
    font-weight: 300 !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    margin-bottom: 0px;
    text-decoration: none !important;
    line-height: 1.4em !important;
}
.IconoPaper{
    float: left;
    font-size: .875rem !important;
    padding: 29px !important;
    margin-top: -32px !important;
    margin-right: 15px !important;
    border-radius: 3px !important;
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
    line-height: 1.5em !important;

}
.TextoTabla{
    padding: 12px 8px!important;
    position: relative;
    font-size: 1em !important;
    border-top: 1px solid #DDD !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 300 !important;
    line-height: 1.42857143 !important;
    border-bottom: none !important;
    vertical-align: middle !important;
}
.IconoPaper i{
    color: white !important;
}
.MargenSuperior{
    margin-top: 15px !important;
}
.colorInfo{
    color: #d4edda !important;
}