.paper{
    max-Width: 400px;
    padding: 16px;
    margin: 8px auto;
}
.card{
    max-width: 330px;
}
.cardheader{
    background-color: #3f51b5;
    padding: .75rem 1.25rem !important;
}
.cardheader span{
    font-size: 1.1rem !important;
    color: #fff !important;
}
.material-icons{
    color: #fff !important;
}