html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;;
 /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

.inframemax{
  position:fixed; 
  top:0 !important; 
  left:0 !important; 
  bottom:0 !important; 
  right:0; 
  height: 100vh;        /* Viewport-relative units */
    width: 100vw; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.espacio_der{
  margin-right: 5px;
}
.container{
  margin-top: 30px;
}
.IconoPaperHome{
  float: left;
  font-size: .875rem !important;
  padding: 15px !important;
  margin-top: -5px !important;
  margin-right: 15px !important;
  border-radius: 3px !important;
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
  line-height: 1.5em !important;

}
.TituloHeader{
  font-size: 1.3em !important;
  color: #3C4858 !important;
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 300 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
  margin-bottom: 0px !important;

}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px white inset !important;
    background: transparent;
}
input:-webkit-autofill { 
  -webkit-background-clip: text !important;
}
.titulomenu_drawer{
  font-size: 1.3em !important;
  color: #3C4858 !important;
}
.texto_15{
  font-size: 15px;
}
.padding_15{
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 15px;
}
.top_25{
   margin-top: 25px;
}
.spacio_rigth15 {
  margin-right: 15px !important;
}

.stiloimput{
  max-width: 70% !important;
  background-color:#FEF9E7;
  text-align: center !important;
}

.nota_perdida{
    background-color: #F9D7D4;
    color: red !important;    
    font-weight: 450 !important;
}
.nota_perdida_c{
  color: red !important;    
}
.campos_4{
  border-top: 1px solid #d81b60;
  font-weight: 450 !important;
  
}