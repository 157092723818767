.btn-space{
    margin-right: 5px;
}
.PaddingPaper {
    padding: .75rem 1.25rem !important;
}
.MargenBoton {
    margin-right: .25rem !important;
}
.MargenButton{
    margin-bottom: .45rem !important;
}
.AltoFila{
    height: 40px !important;
}
.ColorFuxia{
    color: #d81b60 !important;
}
.colorVerde{
    color: #43a047 !important;
  }
  .colorAzul{
    color: rgb(23, 105, 170) !important;
  }
.FilaEncabezadoTabla{
     font-weight: 300 !important;
     font-size: 1.25em !important;
     line-height: 1.42857143 !important;
     color: rgba(0, 0, 0, 0.87) !important;
     
 }
 .Titulo{
     font-size: 1.3em !important;
     color: #3C4858 !important;
     margin-top: 0 !important;
     min-height: auto !important;
     font-weight: 300 !important;
     font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
     margin-bottom: 0px;
     text-decoration: none !important;
     line-height: 1.4em !important;
     margin-bottom: 15px !important;
 
 }
 .IconoPaper{
     float: left;
     font-size: .875rem !important;
     padding: 29px !important;
     margin-top: -32px !important;
     margin-right: 15px !important;
     border-radius: 3px !important;
     background: linear-gradient(60deg, #ec407a, #d81b60);
     box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
     line-height: 1.5em !important;
 
 }
 .TextoTabla{
     padding: 12px 8px!important;
     position: relative;
     font-size: 1em !important;
     border-top: 1px solid #DDD !important;
     font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
     font-weight: 300 !important;
     line-height: 1.42857143 !important;
     border-bottom: none !important;
     vertical-align: middle !important;
 }
 .IconoPaper i{
     color: white !important;
 }
 .MargenSuperior{
     margin-top: 15px !important;
 }