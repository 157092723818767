.quitarpading input{
    padding: 0;
}
.sprom{
    background-color: #A5FCB1;
}
.promfin{
    background-color: #B1CEEB;
}
.table tr td, table tr th{
    vertical-align: middle !important;


}
table.tablcal thead tr th, table.tablcal thead tr td {
    background-color: #3f51b5 !important;
    border: 1px solid #2339A8 !important;
    color: #fff;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;    
}
.tblcalificaciones tbody tr td{
    font-size: 0.89rem;
}
.siglasNotas{
    padding: 5px 0px;
}
.coloramarillo{
    color: darkorange !important; 
}