.main {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 124px;
}
.paper{
    display: flex;
    padding: 16px 24px 24px;
    margin-top: 64px;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}
.formulario{
  width: 100%;
  margin-top: 8px;
}
.avatar{
  margin: 8px;
  background-color: rgb(225, 0, 80);
}