.root {
    flex-Grow: 1;
}
.grow {    
    flex-Grow: 1;
}
.menuButton {
    margin-Left: -12px;
    margin-right: 20px;
}
.jss15 {
    margin: 0;
    padding: 15px 0px;
    display: block;
    z-index: 4;
    position: relative;
}
/*
.jss16 {
    float: left;
    width: 30px;
    color: inherit;
    opacity: 1;
    display: inline-block;
    transition: all 300ms linear;
    text-align: center;
    max-height: 30px;
    margin-top: 7px;
    margin-left: 22px;
    margin-right: 18px;
}
*/
.jss18 {
    display: block;
    opacity: 1;
    padding: 5px 0px;
    overflow: hidden;
    transform: translate3d(0px, 0, 0);
    font-size: 18px;
    transition: all 300ms linear;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
    text-transform: uppercase;
}
.ColorFuxia{
    color: #d81b60 !important;
}
.colorVerde{
    color: #43a047 !important;
}
.colorAzul{
    color: rgb(23, 105, 170) !important;
}
.fondoBarra{
    background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(68,89,150,1) 0%, rgba(13,89,193,1) 81%) !important;
}