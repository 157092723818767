
#root {
      /* Login */
    /*  background-image: url('https://ga7a0b6c9043600-apexprod.adb.us-phoenix-1.oraclecloudapps.com/ords/r/misiontic/100/files/static/v16/foto-campus-1-peke.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .92; */
      height: 100vh;
      position: absolute;
      width: 100%;
}
.mainLogin{
   /* background-image: url('https://ga7a0b6c9043600-apexprod.adb.us-phoenix-1.oraclecloudapps.com/ords/r/misiontic/100/files/static/v16/foto-campus-1-peke.jpg');*/
    /* background-image: url('https://cdn.pixabay.com/photo/2016/03/26/13/09/workspace-1280538_1280.jpg'); */
    background-image: url('/images/background_3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .92; 
    height: 100vh;
    position: absolute;
    width: 100%;
}
.main {
  /*  width: 400px;*/
    margin-left: auto;
    margin-right: auto;
   /* margin-top: 124px;*/

}
.paper{
    display: flex;
    padding: 16px 24px 24px;
    background: rgba(255, 255, 255, 0.65) !important;
    backdrop-filter: blur(4px);
    margin-top: 30%;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.formulario{
  width: 100%;
  margin-top: 8px;
}
.avatar{
  margin: 8px;
  background-color: rgb(225, 0, 80);
}
.botonlogin{
  border-radius: 28px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(79, 70, 229);
}
.cont_logo_col{
  text-align: center;
}
.institucion{
  margin: auto;
  width: 500px;
}