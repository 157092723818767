.maximoancho{
    width: 370px !important;
    margin: auto;
    margin-top: 150px !important;
}
.Contenido{
    font-size: 14px !important;
    font-weight: 100;
}
.imgtiempo{
    width: 100px !important;
    margin: auto !important;
    padding: 10px;
}
.img_ancho{
    width: 100px !important;
    margin: auto !important;
}