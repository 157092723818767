html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;;
 /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

.inframemax{
  position:fixed; 
  top:0 !important; 
  left:0 !important; 
  bottom:0 !important; 
  right:0; 
  height: 100vh;        /* Viewport-relative units */
    width: 100vw; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.espacio_der{
  margin-right: 5px;
}
.container{
  margin-top: 30px;
}
.IconoPaperHome{
  float: left;
  font-size: .875rem !important;
  padding: 15px !important;
  margin-top: -5px !important;
  margin-right: 15px !important;
  border-radius: 3px !important;
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
  line-height: 1.5em !important;

}
.TituloHeader{
  font-size: 1.3em !important;
  color: #3C4858 !important;
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 300 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
  margin-bottom: 0px !important;

}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px white inset !important;
    background: transparent;
}
input:-webkit-autofill { 
  -webkit-background-clip: text !important;
}
.titulomenu_drawer{
  font-size: 1.3em !important;
  color: #3C4858 !important;
}
.texto_15{
  font-size: 15px;
}
.padding_15{
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 15px;
}
.top_25{
   margin-top: 25px;
}
.spacio_rigth15 {
  margin-right: 15px !important;
}

.stiloimput{
  max-width: 70% !important;
  background-color:#FEF9E7;
  text-align: center !important;
}

.nota_perdida{
    background-color: #F9D7D4;
    color: red !important;    
    font-weight: 450 !important;
}
.nota_perdida_c{
  color: red !important;    
}
.campos_4{
  border-top: 1px solid #d81b60;
  font-weight: 450 !important;
  
}

#root {
      /* Login */
    /*  background-image: url('https://ga7a0b6c9043600-apexprod.adb.us-phoenix-1.oraclecloudapps.com/ords/r/misiontic/100/files/static/v16/foto-campus-1-peke.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .92; */
      height: 100vh;
      position: absolute;
      width: 100%;
}
.mainLogin{
   /* background-image: url('https://ga7a0b6c9043600-apexprod.adb.us-phoenix-1.oraclecloudapps.com/ords/r/misiontic/100/files/static/v16/foto-campus-1-peke.jpg');*/
    /* background-image: url('https://cdn.pixabay.com/photo/2016/03/26/13/09/workspace-1280538_1280.jpg'); */
    background-image: url('/images/background_3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .92; 
    height: 100vh;
    position: absolute;
    width: 100%;
}
.main {
  /*  width: 400px;*/
    margin-left: auto;
    margin-right: auto;
   /* margin-top: 124px;*/

}
.paper{
    display: -webkit-flex;
    display: flex;
    padding: 16px 24px 24px;
    background: rgba(255, 255, 255, 0.65) !important;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    margin-top: 30%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.formulario{
  width: 100%;
  margin-top: 8px;
}
.avatar{
  margin: 8px;
  background-color: rgb(225, 0, 80);
}
.botonlogin{
  border-radius: 28px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(79, 70, 229);
}
.cont_logo_col{
  text-align: center;
}
.institucion{
  margin: auto;
  width: 500px;
}
#root {
  /* Home */
  /*  background-image: none;*/
  /*  background-color: rgb(241, 241, 241);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;*/
    height: 100vh;
    position: absolute;
    width: 100%;
}
  #fondoHome{
    background-color: rgb(241, 241, 241);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    position: absolute;
    width: 100%;
  }
  .navbar-login
  {
      width: 305px;
      padding: 10px;
      padding-bottom: 0px;
  }
  
  .navbar-login-session
  {
      padding: 10px;
      padding-bottom: 0px;
      padding-top: 0px;
  }
  
  .icon-size
  {
      font-size: 87px;
  }

  .anchocard{
    width: 300px !important;
  }
  .loading{
      position: absolute;
      left: 50%;
      top: 50%;      
      width: 200px;
      height: 200px;
      margin-left: -100px; /*half width*/
      margin-top: -100px; /*half height*/      
      background-image: url('/images/loadingpts.gif')
  }
  .logoapp{
      position: absolute;
      left: 50%;
      top: 50%;      
      width: 116px;
      height: 108px;
      margin-left: -58px; /*half width*/
      margin-top: -58px; /*half height*/      
      background-image: url('/images/LogoCnotasGr.png')      
  }
  .ColorFuxia{
    color: #d81b60 !important;
  }
  .colorVerde{
    color: #43a047 !important;
  }
  .colorAzul{
    color: rgb(23, 105, 170) !important;
  }
.root {
    flex-Grow: 1;
}
.grow {    
    flex-Grow: 1;
}
.menuButton {
    margin-Left: -12px;
    margin-right: 20px;
}
.jss15 {
    margin: 0;
    padding: 15px 0px;
    display: block;
    z-index: 4;
    position: relative;
}
/*
.jss16 {
    float: left;
    width: 30px;
    color: inherit;
    opacity: 1;
    display: inline-block;
    transition: all 300ms linear;
    text-align: center;
    max-height: 30px;
    margin-top: 7px;
    margin-left: 22px;
    margin-right: 18px;
}
*/
.jss18 {
    display: block;
    opacity: 1;
    padding: 5px 0px;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
    font-size: 18px;
    transition: all 300ms linear;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
    text-transform: uppercase;
}
.ColorFuxia{
    color: #d81b60 !important;
}
.colorVerde{
    color: #43a047 !important;
}
.colorAzul{
    color: rgb(23, 105, 170) !important;
}
.fondoBarra{
    background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(68,89,150,1) 0%, rgba(13,89,193,1) 81%) !important;
}
.paper{
    max-Width: 400px;
    padding: 16px;
    margin: 8px auto;
}
.card{
    max-width: 330px;
}
.cardheader{
    background-color: #3f51b5;
    padding: .75rem 1.25rem !important;
}
.cardheader span{
    font-size: 1.1rem !important;
    color: #fff !important;
}
.material-icons{
    color: #fff !important;
}
.btn-space{
    margin-right: 5px;
}
.PaddingPaper {
    padding: .75rem 1.25rem !important;
}
.MargenBoton {
    margin-right: .25rem !important;
}
.MargenButton{
    margin-bottom: .45rem !important;
}
.AltoFila{
    height: 40px !important;
}
.ColorFuxia{
    color: #d81b60 !important;
}
.colorVerde{
    color: #43a047 !important;
  }
  .colorAzul{
    color: rgb(23, 105, 170) !important;
  }
.FilaEncabezadoTabla{
     font-weight: 300 !important;
     font-size: 1.25em !important;
     line-height: 1.42857143 !important;
     color: rgba(0, 0, 0, 0.87) !important;
     
 }
 .Titulo{
     font-size: 1.3em !important;
     color: #3C4858 !important;
     margin-top: 0 !important;
     min-height: auto !important;
     font-weight: 300 !important;
     font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
     margin-bottom: 0px;
     text-decoration: none !important;
     line-height: 1.4em !important;
     margin-bottom: 15px !important;
 
 }
 .IconoPaper{
     float: left;
     font-size: .875rem !important;
     padding: 29px !important;
     margin-top: -32px !important;
     margin-right: 15px !important;
     border-radius: 3px !important;
     background: linear-gradient(60deg, #ec407a, #d81b60);
     box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
     line-height: 1.5em !important;
 
 }
 .TextoTabla{
     padding: 12px 8px!important;
     position: relative;
     font-size: 1em !important;
     border-top: 1px solid #DDD !important;
     font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
     font-weight: 300 !important;
     line-height: 1.42857143 !important;
     border-bottom: none !important;
     vertical-align: middle !important;
 }
 .IconoPaper i{
     color: white !important;
 }
 .MargenSuperior{
     margin-top: 15px !important;
 }
.quitarpading input{
    padding: 0;
}
.card{
    max-width: 1110px !important;
}
.notainfo{
    padding: 5px 0;
}
.quitarpading input{
    padding: 0;
}
.quitarpading input{
    padding: 0;
}
.sprom{
    background-color: #A5FCB1;
}
.promfin{
    background-color: #B1CEEB;
}
.table tr td, table tr th{
    vertical-align: middle !important;


}
table.tablcal thead tr th, table.tablcal thead tr td {
    background-color: #3f51b5 !important;
    border: 1px solid #2339A8 !important;
    color: #fff;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;    
}
.tblcalificaciones tbody tr td{
    font-size: 0.89rem;
}
.siglasNotas{
    padding: 5px 0px;
}
.coloramarillo{
    color: darkorange !important; 
}
.quitarpading input{
    padding: 0;
}
.notainfoEx{
    padding: 5px 0;
}
.imgloading{
    position: absolute;
    left: 50%;
    top: 50%;      
    width: 64px;
    height: 64px;
    margin-left: -32px; /*half width*/
    margin-top: -32px; /*half height*/      
    background-image: url('/images/loading.gif')
}
.PaddingPaper {
    padding: .75rem 1.25rem !important;
}
.MargenBoton {
    margin-right: .25rem !important;
}
.MargenButton{
    margin-bottom: .45rem !important;
}
.AltoFila{
    height: 40px !important;
}
.ColorAzul{
    color: #d81b60 !important;
}
.FilaEncabezadoTabla{
   /* background: rgba(95,117,230,1);
    background: -moz-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(95,117,230,1)), color-stop(50%, rgba(63,81,181,1)), color-stop(100%, rgba(95,117,230,1)));
    background: -webkit-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -o-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: -ms-linear-gradient(top, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    background: linear-gradient(to bottom, rgba(95,117,230,1) 0%, rgba(63,81,181,1) 50%, rgba(95,117,230,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f75e6', endColorstr='#5f75e6', GradientType=0 );*/
    font-weight:bold !important;
    font-size: 0.95em !important;
    line-height: 1.42857143 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    
}
.Titulo{
    font-size: 1.3em !important;
    color: #3C4858 !important;
    margin-top: 0 !important;
    min-height: auto !important;
    font-weight: 300 !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    margin-bottom: 0px;
    text-decoration: none !important;
    line-height: 1.4em !important;

}
.msginfo{
    font-size: 1.0em !important;
    color: #3C4858 !important;
    margin-top: 0 !important;
    min-height: auto !important;
    font-weight: 300 !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    margin-bottom: 0px;
    text-decoration: none !important;
    line-height: 1.4em !important;
}
.IconoPaper{
    float: left;
    font-size: .875rem !important;
    padding: 29px !important;
    margin-top: -32px !important;
    margin-right: 15px !important;
    border-radius: 3px !important;
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4);
    line-height: 1.5em !important;

}
.TextoTabla{
    padding: 12px 8px!important;
    position: relative;
    font-size: 1em !important;
    border-top: 1px solid #DDD !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 300 !important;
    line-height: 1.42857143 !important;
    border-bottom: none !important;
    vertical-align: middle !important;
}
.IconoPaper i{
    color: white !important;
}
.MargenSuperior{
    margin-top: 15px !important;
}
.colorInfo{
    color: #d4edda !important;
}
.quitarpading input{
    padding: 0;
}
.notainfoEx{
    padding: 5px 0;
}
.quitarpading input{
    padding: 0;
}
.card{
    max-width: 1110px !important;
}
.notainfo{
    padding: 5px 0;
}

.main {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 124px;
}
.paper{
    display: -webkit-flex;
    display: flex;
    padding: 16px 24px 24px;
    margin-top: 64px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}
.formulario{
  width: 100%;
  margin-top: 8px;
}
.avatar{
  margin: 8px;
  background-color: rgb(225, 0, 80);
}

.maximoancho{
    width: 370px !important;
    margin: auto;
    margin-top: 150px !important;
}
.Contenido{
    font-size: 14px !important;
    font-weight: 100;
}
.imgtiempo{
    width: 100px !important;
    margin: auto !important;
    padding: 10px;
}
.img_ancho{
    width: 100px !important;
    margin: auto !important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.box{
  display: -webkit-flex;
  display: flex;
}
.fondo-blanco {
  /*  background-color: #fff !important;*/
    border-radius: 0.125rem !important;
    
}
.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(68,89,150,1) 0%, rgba(13,89,193,1) 81%) !important;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}


.content-table th{
  padding: 12px 15px;
}
.content-table td {
  padding: 5px 15px;
}
.content-table td {
  font-size: 15px;
}
.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:hover td {
  background: #e0e0e0;
  cursor: pointer;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid rgba(13,89,193,1);
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
.mag_input_nota{
  margin-top: 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fontRoboto{
  color: #3C4858 !important;
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 300 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
}

.fontTabla{
  /*color: #000 !important;*/
  margin-top: 0 !important;
  min-height: auto !important;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 0px;
  text-decoration: none !important;
  line-height: 1.4em !important;
  font-size: 0.95rem;
}
.lista_materias{
   margin-bottom: 10px;
   font-weight: bold !important;
}

.materias{
   /*text-decoration: underline !important ; */
   margin-right: 10px !important;
}

.App-link {
  color: #61dafb;
}
.input-maximo{
    width: 100% !important;
}
.iconoColorRosa{
  color: #d81b60 !important;
}
.dialog_indicador{
  width: 400px !important;
}
.LeftMargin{
    margin-left: 5px !important;
}
.backdrop_fondo {
  z-index: 1201 !important;
  color: '#fff' !important
}

.flex-container {
  display: -webkit-flex;
  display: flex;  
}

.flex-container > div {
  
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

