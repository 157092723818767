#root {
  /* Home */
  /*  background-image: none;*/
  /*  background-color: rgb(241, 241, 241);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;*/
    height: 100vh;
    position: absolute;
    width: 100%;
}
  #fondoHome{
    background-color: rgb(241, 241, 241);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    position: absolute;
    width: 100%;
  }
  .navbar-login
  {
      width: 305px;
      padding: 10px;
      padding-bottom: 0px;
  }
  
  .navbar-login-session
  {
      padding: 10px;
      padding-bottom: 0px;
      padding-top: 0px;
  }
  
  .icon-size
  {
      font-size: 87px;
  }

  .anchocard{
    width: 300px !important;
  }
  .loading{
      position: absolute;
      left: 50%;
      top: 50%;      
      width: 200px;
      height: 200px;
      margin-left: -100px; /*half width*/
      margin-top: -100px; /*half height*/      
      background-image: url('/images/loadingpts.gif')
  }
  .logoapp{
      position: absolute;
      left: 50%;
      top: 50%;      
      width: 116px;
      height: 108px;
      margin-left: -58px; /*half width*/
      margin-top: -58px; /*half height*/      
      background-image: url('/images/LogoCnotasGr.png')      
  }
  .ColorFuxia{
    color: #d81b60 !important;
  }
  .colorVerde{
    color: #43a047 !important;
  }
  .colorAzul{
    color: rgb(23, 105, 170) !important;
  }